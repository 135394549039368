<template>
  <div class="organizations view">
    <app-header :title="__('view.ingame.organizations.title')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button" :title="__('common.add')" @click="addOrganization" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg" alt="add"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="organizations"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @editOrganization="editOrganization"
          @openCommunityPage="openCommunityPage"
          @sort="sort"
          @deleteRecord="deleteRecord"
          @showChangeLog="showOrganizationLogs"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-organization ref="editOrganization" @updated="refresh" @created="editOrganization"/>
    <organization-logs ref="organizationLogs"/>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import EditOrganization from "../../components/Ingame/EditOrganization";
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import OrganizationLogs from "../../components/Ingame/OrganizationLogs";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      OrganizationLogs,
      AppHeader,
      DataTable,
      EditOrganization,
    },
    data: () => ({
      loading: false,
      organizations: [],
      dataTableFields: [],
      recordActions: [],
    }),
    methods: {
      showOrganizationLogs({id}) {
        this.$refs.organizationLogs.showDialog(id);
      },
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/civilization/organization', { params }).then(async (res) => {
          this.organizations = res.data;
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.organizations_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      addOrganization() {
        this.$refs.editOrganization.showCreate();
      },
      editOrganization(organization) {
        this.$refs.editOrganization.showEdit(organization);
      },
      openCommunityPage(organization) {
        window.open(`https://community.dualthegame.com/organization/${organization.inGameId}/`)
      },
      deleteRecord(record) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('common.do_you_want_to_delete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.yes_delete_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = '/ingame/civilization/organization/' + record.id;
            apiSecured.delete(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('common.deleted'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
    },
    created() {
      this.setPageTitle(this.__('entity.common.organizations'));
      this.dataTableFields = [
        { name: "logo", title: 'Logo', type: "srcOrSvg" },
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "name", title: this.__('entity.common.name'), type: "default" },
        { name: "state", title: this.__('entity.ingame.organization.state_short'), type: "default" },
        { name: "ownerId", title: this.__('entity.ingame.organization.ownerId'), type: "default" },
        { name: "inGameId", title: 'InGame Id', type: "default" },
        { name: "foundedAt", title: this.__('entity.ingame.organization.foundedAt'), type: "datetime" },
        { name: "isFaction", title: this.__('isFaction'), type: "boolean-text" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        {
          title: this.__('view.ingame.organizations.open_community_page'),
          icon: 'open-in-new',
          event: 'openCommunityPage'
        },
        { title: this.__('view.ingame.organization.show_organization_logs'), icon: 'change-log', event: 'showChangeLog' },
        { title: this.__('component.ingame.edit_organization.title'), icon: 'pencil', event: 'editOrganization' },
        {
          title: this.__('view.ingame.organizations.delete_org'),
          icon: 'bin',
          event: 'deleteRecord',
          restrictRoles: []
        }
      ];
      this.refresh();
    }
  }
</script>
