<template>
  <div class="edit-organization">
    <my-dialog :show="show" @close="close" v-if="originalRecord && record">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.ingame.edit_organization.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs4">
              <div class="layout layout--wrap">
                <div class="flex xs12 mt">
                  <text-field
                      v-model="record.name"
                      :label="__('entity.common.name')"
                      :error-messages="errors.name"
                      maxlength="50"
                      :prepend-icon="isChanged('name') ? 'pencil' : null"
                      @input="registerChange('name')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-area
                      v-model="record.logo"
                      :label="'Logo'"
                      :error-messages="errors.logo"
                      :prepend-icon="isChanged('logo') ? 'pencil' : null"
                      @input="registerChange('logo')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-area
                      v-model="record.description"
                      :label="__('entity.common.description')"
                      :error-messages="errors.about"
                      :prepend-icon="isChanged('about') ? 'pencil' : null"
                      @input="registerChange('about')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-field
                      v-model="record.ownerId"
                      :label="__('entity.ingame.organization.ownerId')"
                      :error-messages="errors.ownerId"
                      :type="'number'"
                      :prepend-icon="isChanged('ownerId') ? 'pencil' : null"
                      @input="registerChange('ownerId')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-field
                      v-model="record.inGameId"
                      :label="'InGame Id'"
                      :error-messages="errors.inGameId"
                      :type="'number'"
                      :prepend-icon="isChanged('inGameId') ? 'pencil' : null"
                      @input="registerChange('inGameId')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-field
                      v-model="record.foundedAt"
                      :label="__('entity.ingame.organization.foundedAt')"
                      :error-messages="errors.foundedAt"
                      :type="'number'"
                      :prepend-icon="isChanged('foundedAt') ? 'pencil' : null"
                      @input="registerChange('foundedAt')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{__('entity.ingame.organization.state')}}</label>
                  <single-select
                      :items="states"
                      v-model="record.state"
                      :append-icon="isChanged('state') ? 'pencil' : null"
                      @input="registerChange('state')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <my-switch
                      v-model="record.logoIsSvg"
                      :label="'Logo is svg'"
                      :append-icon="isChanged('logoIsSvg') ? 'pencil' : null"
                      @input="registerChange('logoIsSvg')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <my-switch
                      v-model="record.isFaction"
                      :label="__('isFaction')"
                      :append-icon="isChanged('isFaction') ? 'pencil' : null"
                      @input="registerChange('isFaction')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <div class="btn btn--success" :loading="loading"
                       :disabled="changedProperties.length === 0"
                       @click="update" v-slashes>{{ __('common.save_changes') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex xs8">
              <edit-organization-memberships ref="editOrganizationMemberships" :child-organization-id="record.id"/>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import { editFormMixin } from "../../mixins/editForm";
  import EditOrganizationMemberships from "./EditOrganization/EditOrganizationMemberships";

  export default {
    components: { EditOrganizationMemberships },
    mixins: [localizationMixin, editFormMixin],
    data: () => ({
      endpoint: '/ingame/civilization/organization',
      emptyRecord: {
        id: null,
        name: '',
        state: 'neutral',
        description: '',
        ownerId: null,
        foundedAt: null,
        inGameId: null,
        logo: null,
        logoIsSvg: false,
        isFaction: false,
        createdAt: '---',
        updatedAt: '---'
      },
      states: [
        { value: 'enemy', title: 'enemy' },
        { value: 'neutral', title: 'neutral' },
        { value: 'friendly', title: 'friendly' },
        { value: 'ally', title: 'ally' },
        { value: 'self', title: 'self' }
      ]
    }),
  }
</script>