<template>
  <div class="organizationlogs">
    <my-dialog :show="show" @close="close" :fullscreen="true">
      <div class="card">
        <div class="card__header">
          <div class="card__title">
            <template v-if="organization">{{organization.name}} -</template>
            {{__('organizationLogs')}}
          </div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content" v-if="organization">
          <progress-linear v-if="loading"/>
          <div class="control-bar" :class="{'control-bar--disabled': loading}">
            <div class="control-bar__info">
              <div class="control-bar__info-title">{{__('common.total')}}:</div>
              <div class="control-bar__info-value">{{ pagination.total }}</div>
            </div>
            <div class="control-bar__buttons">
              <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
                <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
                     alt="refresh"/>
              </div>
            </div>
          </div>
          <div class="scroll-horizontal-overflow">
            <pagination
                class="mt"
                :count-pages="pagination.pages"
                :total-visible="10"
                :page="pagination.page"
                :disabled="loading"
                @paginate="paginate"
            />
            <data-table
                :records="organizationLogs"
                :fields="dataTableFields"
                :record-actions="recordActions"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :loading="loading"
                @sort="sort"
            />
            <pagination
                class="mt"
                :count-pages="pagination.pages"
                :total-visible="10"
                :page="pagination.page"
                :disabled="loading"
                @paginate="paginate"
            />
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin],
    components: {
      DataTable,
    },
    data: () => ({
      organizationId: null,
      show: false,
      loading: false,
      organizationLogs: [],
      dataTableFields: [],
      recordActions: [],
      organization: null,
    }),
    watch: {
      organizationId() {
        if(this.organizationId) {
          this.refresh();
        }
      }
    },
    methods: {
      close() {
        this.organization = null;
        this.organizationId = null;
        this.show = null;
      },
      showDialog(organizationId) {
        this.organizationId = organizationId;
        this.show = true;
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        if (this.constructId !== null) {
          params.organizationIds = this.organizationId;
        }
        apiSecured.get('/ingame/civilization/organization/logs', { params }).then(async (res) => {
          this.organizationLogs = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.organizations_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {
        await apiSecured.get(`/ingame/civilization/organization/${this.organizationId}`, {
          params: {},
        }).then((res) => {
          this.organization = res.data;
          this.showInfoNotification('Organization has been reloaded');
        }).catch((error) => {
          this.showErrorNotifications(error)
        });
      },
    },
    created() {
      this.dataTableFields = [
        { name: "logo", title: 'Logo', type: "srcOrSvg" },
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "name", title: this.__('entity.common.name'), type: "default" },
        { name: "state", title: this.__('entity.ingame.organization.state_short'), type: "default" },
        { name: "ownerId", title: this.__('entity.ingame.organization.ownerId'), type: "default" },
        { name: "inGameId", title: 'InGame Id', type: "default" },
        { name: "foundedAt", title: this.__('entity.ingame.organization.foundedAt'), type: "datetime" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        {
          title: this.__('view.ingame.organizations.open_community_page'),
          icon: 'open-in-new',
          event: 'openCommunityPage'
        },
        { title: this.__('component.ingame.edit_organization.title'), icon: 'pencil', event: 'editOrganization' },
        {
          title: this.__('view.ingame.organizations.delete_org'),
          icon: 'bin',
          event: 'deleteRecord',
          restrictRoles: []
        }
      ];
    }
  }
</script>
